import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const ProductFinder = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const element = useRef<HTMLElement>(null);

  useEffect(() => {
    element.current?.addEventListener('savePet', (customEvent: any /* use your own types instead of any */) => {
      console.log('savePet', customEvent);
      navigate('/my-account/home', { state: customEvent?.detail });
    });
  });

  return (
    <product-finder
      ref={element}
      locale="fr-FR"
      country="FR"
      current_location={JSON.stringify(location)}
      pet={JSON.stringify((location.state as { pet?: unknown })?.pet)}
      with_save_pet="true"
    ></product-finder>
  );
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'product-finder': any;
    }
  }
}
