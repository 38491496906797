import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MyAccount } from './components/my-account/MyAccount';
import { ProductFinder } from './components/product-finder/ProductFinder';
import { Home } from './components/home/Home';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="product-finder/*" element={<ProductFinder />} />
        <Route path="my-account/*" element={<MyAccount />} />
        <Route path="home" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
