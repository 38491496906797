import './App.css';
import { Outlet, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const CUSTOM_USER_UPDATED_EVENT = 'userUpdated';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    window.addEventListener(CUSTOM_USER_UPDATED_EVENT, (event) => {
      if (event instanceof CustomEvent) {
        console.log('userUpdated event', event?.detail);
        setUser(event?.detail);
      }
    });
  }, []);

  return (
    <div className="app">
      <header className="app-header">
        <h1 className="app-header-title">I AM A SAMPLE APPLICATION</h1>

        <div className="app-header-links">
          <Link className="app-header-link" to="/home">
            Home
          </Link>
          <Link className="app-header-link" to="/product-finder/tree">
            Product finder
          </Link>
          <Link className="app-header-link" to="/my-account">
            My Account (MA)
          </Link>
          <Link className="app-header-link" to="/my-account/pets">
            MA pets deeplink
          </Link>
          <Link className="app-header-link" to="/my-account/manager/personal-infos">
            MA personal informations deeplink
          </Link>
          <Link className="app-header-link" to="/my-account/register">
            MA register deeplink
          </Link>
          {user && (
            <Link className="app-header-link" to="/my-account/logout">
              MA logout deeplink
            </Link>
          )}
        </div>
      </header>

      <Outlet />
    </div>
  );
}

export default App;
