import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const MyAccount = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const element = useRef<HTMLElement>(null);

  useEffect(() => {
    const currentRef = element.current;
    const getRecommendationCallback = (customEvent: any /* use your own types instead of any */) => {
      navigate('/product-finder/tree', { state: customEvent.detail });
    };
    currentRef?.addEventListener('getRecommendation', getRecommendationCallback);

    const viewProductDetailsCallback = (customEvent: any /* use your own types instead of any */) => {
      console.log('view product details :', customEvent.detail);
      alert('Check your console to see product details !');
    };

    currentRef?.addEventListener('viewProductDetails', viewProductDetailsCallback);

    // this callback is active only if the params with_pet_selection_callback is set to true
    const selectPetCallback = (customEvent: any /* use your own types instead of any */) => {
      console.log('select pet details :', customEvent.detail);
      alert('Check your console to see pet details !');
    };
    currentRef?.addEventListener('selectPet', selectPetCallback);

    // this callback is active only if the params with_pet_creation is set to 'custom' or 'notify'
    const petCreatedCallback = (customEvent: any /* use your own types instead of any */) => {
      console.log('pet created details :', customEvent.detail);
       alert('Check your console to see pet details !');
    };
    currentRef?.addEventListener('petCreated', petCreatedCallback);

    // Add to cart callback
    const addToCartCallback = (customEvent: any /* use your own types instead of any */) => {
      console.log('Add to cart details :', customEvent.detail);
       alert('Check your console to see add to cart details !');
    };
    currentRef?.addEventListener('addToCart', addToCartCallback);

    return () => {
      currentRef?.removeEventListener('getRecommendation', getRecommendationCallback);
      currentRef?.removeEventListener('viewProductDetails', viewProductDetailsCallback);
      currentRef?.removeEventListener('selectPet', selectPetCallback);
      currentRef?.removeEventListener('petCreated', petCreatedCallback);
      currentRef?.removeEventListener('addToCart', addToCartCallback);
    };
  }, [navigate]);

  return (
    <my-account
      ref={element}
      // Check .env file to see the content of REACT_APP_MA_WEBCOMP_LOCATION_URI and REACT_APP_MA_WEBCOMP_SIGNOUT_URI variables
      location_uri={process.env.REACT_APP_MA_WEBCOMP_LOCATION_URI}
      signout_redirect_uri={process.env.REACT_APP_MA_WEBCOMP_SIGNOUT_URI}
      with_product_finder="true"
      current_location={JSON.stringify(location)}
      data={JSON.stringify(location.state)}
      with_pet_selection_callback="false"
      with_pet_creation="notify"
    ></my-account>
  );
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'my-account': any;
    }
  }
}
